import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from 'Home';
import Mint from 'Mint';

import { AnimatePresence } from 'framer-motion';

function AnimtedRoutes() {

    return (

        <AnimatePresence>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/mint' element={<Mint />} />
            </Routes>
        </AnimatePresence>
        
    )
}
export default AnimtedRoutes