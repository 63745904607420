/* global BigInt */
import React, { useState, useEffect, useRef } from 'react';
import ConnectInline from 'components/ConnectInline';
import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import {
    useAccount,
    useContractRead,
    useContractWrite,
    useWaitForTransaction,
} from 'wagmi';
import { Link } from 'react-router-dom';
import contractInterface from 'contracts/contract-abi.json';
// import contractInterface from 'contracts/SWSxNFT.json';
import * as Background from 'components/Animations';
import { ReactComponent as Etherscan } from 'assets/etherscan.svg';
import { ReactComponent as Opensea } from 'assets/opensea.svg';
import { ReactComponent as Rarible } from 'assets/rarible.svg';

const contractConfig = {
    addressOrName: process.env.REACT_APP_NFT_CONTRACT_ADDRESS,
    contractInterface: contractInterface
};

const Mint = () => {

    const [ totalMinted, setTotalMinted ] = useState(0);
    const { isConnected } = useAccount();
    const [ numberOfTokens, setNumberOfTokens ] = useState('1');
    const [ tokenID, setTokenID ] = useState('0');
    const [ payable, setPayable ] = useState(BigInt(120000000000000000).toString());

    const {
        data: mintData,
        write: mint,
        isLoading: isMintLoading,
        isSuccess: isMintStarted,
        error: mintError,
    } = useContractWrite({ ...contractConfig, functionName: 'mintPublicSale', overrides: {
        value: payable,
      }, args: [numberOfTokens] });

    const { data: getLastTokenIdData } = useContractRead({
        ...contractConfig,
        functionName: 'getLastTokenId',
        watch: true,
    });

    const { data: MAX_TOTAL_SUPPLY } = useContractRead({
        ...contractConfig,
        functionName: 'MAX_TOTAL_SUPPLY',
        watch: true,
    });

    const { data: PUBLIC_SALE_PRICE } = useContractRead({
        ...contractConfig,
        functionName: 'PUBLIC_SALE_PRICE',
        watch: true,
    });

    const {
        isSuccess: txSuccess,
        error: txError
    } = useWaitForTransaction({
        hash: mintData?.hash,
        wait: mint.data?.wait,
        onSuccess(data) {
            setTokenID(Number(data.logs[0].topics[3]))
        }
    });

    const [ classMinting, setClassMinting ] = useAtom(Background.classMintingAtom);
    const [ initialBackgroundTop, setInitialBackgroundTop ] = useAtom(Background.initialBackgroundTopAtom);
    const [ animateBackgroundTop, setAnimateBackgroundTop ] = useAtom(Background.animateBackgroundTopAtom);
    const [ initialBackgroundLeft, setInitialBackgroundLeft ] = useAtom(Background.initialBackgroundLeftAtom);
    const [ animateBackgroundLeft, setAnimateBackgroundLeft ] = useAtom(Background.animateBackgroundLeftAtom);
    const [ initialBackgroundOpacity, setInitialBackgroundOpacity ] = useAtom(Background.initialBackgroundOpacityAtom);
    const [ animateBackgroundOpacity, setAnimateBackgroundOpacity ] = useAtom(Background.animateBackgroundOpacityAtom);

    useEffect(() => {
        if (getLastTokenIdData) {
            setTotalMinted(getLastTokenIdData.toNumber());
        }
        if (mintError) {
            setClassMinting('idle')
        }
        if (txSuccess) {
            setClassMinting('success')
            setAnimateBackgroundOpacity(1);
        }
    }, [setAnimateBackgroundOpacity, setClassMinting, setTotalMinted, getLastTokenIdData, mintError, txSuccess]);

    const isMinted = txSuccess;

    const trigger=()=>{
        setClassMinting('minting')
        mint();
    }

    const exitTrigger = () => {
        setAnimateBackgroundOpacity(0);
    }

    useEffect(() => {
        setInitialBackgroundTop(animateBackgroundTop);
        setInitialBackgroundLeft(animateBackgroundLeft);
        setInitialBackgroundOpacity(animateBackgroundOpacity);
        setAnimateBackgroundTop('0px');
        setAnimateBackgroundLeft('0px');
    })

    return (

        <div className='RouteWrapper MintWrapper'>

            <Link className='absolute link' to='/' onClick={() => exitTrigger()} style={{ zIndex: '1' }}>&larr;</Link>

        {/* ### SKY BACKGROUND ### */}

            <Background.Background
                classMintingAtom={classMinting}
                initialBackgroundTopAtom={initialBackgroundTop}
                animateBackgroundTopAtom={animateBackgroundTop}
                initialBackgroundLeftAtom={initialBackgroundLeft}
                animateBackgroundLeftAtom={animateBackgroundLeft}
                initialBackgroundOpacityAtom={initialBackgroundOpacity}
                animateBackgroundOpacityAtom={animateBackgroundOpacity}
            />

            <div
                className={`main-content ${classMinting}`}
            >

                <div className='viewport'>

                    <motion.div
                        className={`container Mint ${classMinting}`}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ type: 'tween', duration: 0.4 }}
                    >

                        <div style={{ flex: '1 1 auto' }} className={`Mint ${classMinting}`}>
                            <div>

                                <p style={{ margin: '0 0 2rem 0', textAlign: 'center' }}>ID: {tokenID} / {String(MAX_TOTAL_SUPPLY)}</p>

                                {mintError && (
                                    <p style={{ marginTop: 24, color: '#FF6257' }}>
                                        d Error: {mintError.message}
                                    </p>
                                )}

                                {txError && (
                                    <p style={{ marginTop: 24, color: '#FF6257' }}>
                                        Error: {txError.message}
                                    </p>
                                )}

                                {isConnected && !isMinted && (
                                    <>
                                        <button
                                            style={{ margin: '24px auto 0', maxWidth: '200px', display: 'block' }}
                                            disabled={isMintLoading || isMintStarted}
                                            className={`btn--primary lg mint ${classMinting}`}
                                            data-mint-loading={isMintLoading}
                                            data-mint-started={isMintStarted}
                                            onClick={() => trigger()}

                                        >
                                            {isMintLoading && 'Confirm ..'}
                                            {isMintStarted && 'Minting...'}
                                            {!isMintLoading && !isMintStarted && 'Mint NFT'}
                                        </button>
                                    </>
                                )}

                                {isConnected && isMinted && (
                                    <>
                                    <img src={`https://gateway.pinata.cloud/ipfs/QmPA9tgLiC4SngK8LfRRVoPg6g1VbKmjQiQCiHfmivJgzU/1.gif`} alt='NFT' width={240} height={135} />
                                    <p style={{ marginBottom: 6, position: 'relative', zIndex: 2, left: '0rem', bottom: 0, verticalAlign: 'top', textAlign: 'center' }}>
                                        <a className='ml10 mr10' href={`https://etherscan.io/tx/${mintData?.hash}`} target='_blank' rel='noreferrer'><Etherscan /></a>
                                        <a className='ml10 mr10' target='_blank' rel='noreferrer'  href={`https://opensea.io/assets/${mintData?.to}/${tokenID}`}><Opensea /></a>
                                        <a className='ml10 mr10' target='_blank' rel='noreferrer'  href={`https://rarible.com/token/${mintData?.to}:${tokenID}`}><Rarible /></a>
                                    </p>
                                    </>
                                )}

                                {!isConnected && (
                                    <div className="MainConnect"><ConnectInline></ConnectInline></div>
                                )}

                            </div>

                        </div>

                    </motion.div>

                </div>

            </div>

        </div>
    );
};

export default Mint
