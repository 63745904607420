import MediaQuery from 'react-responsive'
import { ConnectButton } from '@rainbow-me/rainbowkit';

const Connect = () => {
    
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        mounted,
      }) => {
        return (
          <div className='connect'
            {...(!mounted && {
              'aria-hidden': true,
              'style': {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!mounted || !account || !chain) {
                return (
                    <>
                    <MediaQuery maxWidth={749}>
                      <div onClick={openConnectModal}>
                            🔴
                      </div>
                    </MediaQuery>
                    <MediaQuery minWidth={749}>
                      <button onClick={openConnectModal} type='button' className='btn--primary'>
                            Connect Wallet
                      </button>
                      </MediaQuery>
                    </>

                );
              }
              if (chain.unsupported) {
                return (
                  <button onClick={openChainModal} type='button' className='btn--primary'>
                    Wrong network
                  </button>
                );
              }
              return (
                <div style={{ display: 'flex', gap: 12 }}>
                  <div onClick={openAccountModal}>
                    <MediaQuery maxWidth={749}>
                        🟢
                    </MediaQuery>
                    <MediaQuery minWidth={749}>
                    <button className='btn--primary'>
                    {account.displayName}
                    {account.displayBalance
                      ? ` (${account.displayBalance})`
                      : ''}
                      </button>
                      </MediaQuery>
                  </div>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default Connect