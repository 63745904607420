import { motion } from 'framer-motion';

const Stars = ({ color, viewBox, classIs }) => {
    
    return (
        <motion.svg id='Stars' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 1400 900'>
             <switch>
                <g>
                    <g>
                        <g>
                            <g>
                                <circle cx='552' cy='90' r='1.1'/>
                                <circle cx='122.3' cy='201.2' r='1.1'/>
                                <circle cx='73.7' cy='111.1' r='1.1'/>
                                <circle cx='68.8' cy='50.6' r='1.1'/>
                                <circle cx='32.1' cy='75.7' r='1.1'/>
                                <circle cx='213' cy='64.1' r='1.1'/>
                                <circle cx='367.2' cy='323.4' r='1.1'/>
                                <circle cx='338.8' cy='420.5' r='1.1'/>
                                <circle cx='122.3' cy='321.5' r='1.1'/>
                                <circle cx='338.3' cy='146.2' r='1.1'/>
                                <circle cx='137.3' cy='61.4' r='0.9'/>
                                <circle cx='52.1' cy='283' r='0.9'/>
                                <circle cx='430.3' cy='249.9' r='0.9'/>
                                <circle cx='307.9' cy='249' r='0.9'/>
                                <circle cx='429.4' cy='450' r='0.9'/>
                                <circle cx='307.9' cy='392.3' r='0.9'/>
                                <circle cx='51.2' cy='391.4' r='0.9'/>
                                <circle cx='372.8' cy='66' r='0.9'/>
                                <circle cx='193.2' cy='240.1' r='1.7'/>
                                <circle cx='555.7' cy='213.3' r='1.7'/>
                                <circle cx='218.6' cy='395.1' r='1.7'/>
                            </g>
                            <g>
                                <circle cx='1186.1' cy='153.8' r='1.1'/>
                                <circle cx='756.5' cy='265' r='1.1'/>
                                <circle cx='707.8' cy='175.6' r='1.1'/>
                                <circle cx='674.3' cy='96.1' r='1.1'/>
                                <circle cx='847.2' cy='127.9' r='1.1'/>
                                <circle cx='1001.3' cy='387.1' r='1.1'/>
                                <circle cx='973' cy='484.3' r='1.1'/>
                                <circle cx='756.5' cy='385.3' r='1.1'/>
                                <circle cx='972.5' cy='209.9' r='1.1'/>
                                <circle cx='771.4' cy='125.2' r='0.9'/>
                                <circle cx='686.2' cy='346.8' r='0.9'/>
                                <circle cx='1064.5' cy='313.7' r='0.9'/>
                                <circle cx='942.1' cy='312.8' r='0.9'/>
                                <circle cx='1063.6' cy='513.8' r='0.9'/>
                                <circle cx='942.1' cy='456.1' r='0.9'/>
                                <circle cx='685.3' cy='455.2' r='0.9'/>
                                <circle cx='1006.9' cy='129.8' r='0.9'/>
                                <circle cx='827.4' cy='303.9' r='1.7'/>
                                <circle cx='1189.8' cy='277.1' r='1.7'/>
                                <circle cx='852.7' cy='458.9' r='1.7'/>
                            </g>
                            <g>
                                <circle cx='781.6' cy='401.5' r='1.1'/>
                                <circle cx='1211.2' cy='290.3' r='1.1'/>
                                <circle cx='1259.9' cy='379.7' r='1.1'/>
                                <circle cx='1293.4' cy='459.2' r='1.1'/>
                                <circle cx='1120.5' cy='427.4' r='1.1'/>
                                <circle cx='966.4' cy='168.2' r='1.1'/>
                                <circle cx='994.7' cy='71' r='1.1'/>
                                <circle cx='1365.7' cy='186.3' r='1.1'/>
                                <circle cx='995.2' cy='345.4' r='1.1'/>
                                <circle cx='1196.3' cy='430.1' r='0.9'/>
                                <circle cx='1281.4' cy='208.5' r='0.9'/>
                                <circle cx='903.2' cy='241.6' r='0.9'/>
                                <circle cx='1025.6' cy='242.5' r='0.9'/>
                                <circle cx='904.1' cy='41.5' r='0.9'/>
                                <circle cx='1025.6' cy='99.2' r='0.9'/>
                                <circle cx='1282.4' cy='100.1' r='0.9'/>
                                <circle cx='960.8' cy='425.6' r='0.9'/>
                                <circle cx='1144' cy='152' r='1.7'/>
                                <circle cx='777.9' cy='278.2' r='1.7'/>
                                <circle cx='1318.7' cy='67' r='1.7'/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <circle cx='583.1' cy='157.3' r='1.4'/>
                                <circle cx='252.5' cy='242.9' r='1.4'/>
                                <circle cx='215.1' cy='174.2' r='1.4'/>
                                <circle cx='189.3' cy='113' r='1.4'/>
                                <circle cx='322.3' cy='137.5' r='1.4'/>
                                <circle cx='440.9' cy='336.9' r='1.4'/>
                                <circle cx='419.1' cy='411.7' r='1.4'/>
                                <circle cx='252.5' cy='335.5' r='1.4'/>
                                <circle cx='418.7' cy='200.6' r='1.4'/>
                                <circle cx='264' cy='135.3' r='0.7'/>
                                <circle cx='198.5' cy='305.9' r='0.7'/>
                                <circle cx='489.5' cy='280.4' r='0.7'/>
                                <circle cx='395.3' cy='279.7' r='0.7'/>
                                <circle cx='488.8' cy='434.4' r='0.7'/>
                                <circle cx='395.3' cy='390' r='0.7'/>
                                <circle cx='197.8' cy='389.3' r='0.7'/>
                                <circle cx='445.2' cy='138.9' r='0.7'/>
                                <circle cx='307.1' cy='272.8' r='1.2'/>
                                <circle cx='586' cy='252.2' r='1.2'/>
                                <circle cx='326.6' cy='392.1' r='1.2'/>
                            </g>
                            <g>
                                <circle cx='1071.1' cy='206.4' r='1.4'/>
                                <circle cx='740.5' cy='292' r='1.4'/>
                                <circle cx='703.1' cy='223.2' r='1.4'/>
                                <circle cx='677.3' cy='162.1' r='1.4'/>
                                <circle cx='810.3' cy='186.5' r='1.4'/>
                                <circle cx='928.9' cy='386' r='1.4'/>
                                <circle cx='907.1' cy='460.8' r='1.4'/>
                                <circle cx='740.5' cy='384.6' r='1.4'/>
                                <circle cx='906.7' cy='249.6' r='1.4'/>
                                <circle cx='752' cy='184.4' r='0.7'/>
                                <circle cx='686.5' cy='354.9' r='0.7'/>
                                <circle cx='977.5' cy='329.5' r='0.7'/>
                                <circle cx='883.3' cy='328.8' r='0.7'/>
                                <circle cx='976.8' cy='483.5' r='0.7'/>
                                <circle cx='883.3' cy='439.1' r='0.7'/>
                                <circle cx='685.8' cy='438.4' r='0.7'/>
                                <circle cx='933.2' cy='187.9' r='0.7'/>
                                <circle cx='795.1' cy='321.9' r='1.2'/>
                                <circle cx='1074' cy='301.3' r='1.2'/>
                                <circle cx='814.6' cy='441.2' r='1.2'/>
                            </g>
                            <g>
                                <circle cx='759.8' cy='397.1' r='1.4'/>
                                <circle cx='1090.4' cy='311.5' r='1.4'/>
                                <circle cx='1127.9' cy='380.3' r='1.4'/>
                                <circle cx='1153.6' cy='441.4' r='1.4'/>
                                <circle cx='1020.6' cy='417' r='1.4'/>
                                <circle cx='902' cy='217.5' r='1.4'/>
                                <circle cx='923.8' cy='142.8' r='1.4'/>
                                <circle cx='1209.3' cy='231.5' r='1.4'/>
                                <circle cx='924.2' cy='353.9' r='1.4'/>
                                <circle cx='1078.9' cy='419.1' r='0.7'/>
                                <circle cx='1144.5' cy='248.6' r='0.7'/>
                                <circle cx='853.4' cy='274' r='0.7'/>
                                <circle cx='947.6' cy='274.7' r='0.7'/>
                                <circle cx='854.1' cy='120.1' r='0.7'/>
                                <circle cx='947.6' cy='164.4' r='0.7'/>
                                <circle cx='1145.2' cy='165.1' r='0.7'/>
                                <circle cx='897.7' cy='415.6' r='0.7'/>
                                <circle cx='1038.7' cy='205' r='1.2'/>
                                <circle cx='757' cy='302.2' r='1.2'/>
                                <circle cx='1209.3' cy='103' r='1.2'/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <circle cx='467.7' cy='379.9' r='1.4'/>
                                <circle cx='137.1' cy='465.5' r='1.4'/>
                                <circle cx='99.6' cy='396.7' r='1.4'/>
                                <circle cx='73.8' cy='335.5' r='1.4'/>
                                <circle cx='206.9' cy='360' r='1.4'/>
                                <circle cx='325.5' cy='559.5' r='1.4'/>
                                <circle cx='303.7' cy='634.2' r='1.4'/>
                                <circle cx='137.1' cy='558.1' r='1.4'/>
                                <circle cx='303.3' cy='423.1' r='1.4'/>
                                <circle cx='148.5' cy='357.9' r='0.7'/>
                                <circle cx='83' cy='528.4' r='0.7'/>
                                <circle cx='374.1' cy='503' r='0.7'/>
                                <circle cx='279.9' cy='502.3' r='0.7'/>
                                <circle cx='373.4' cy='656.9' r='0.7'/>
                                <circle cx='279.9' cy='612.6' r='0.7'/>
                                <circle cx='82.3' cy='611.9' r='0.7'/>
                                <circle cx='329.8' cy='361.4' r='0.7'/>
                                <circle cx='191.6' cy='495.4' r='1.2'/>
                                <circle cx='470.5' cy='474.8' r='1.2'/>
                                <circle cx='211.1' cy='614.7' r='1.2'/>
                            </g>
                            <g>
                                <circle cx='955.7' cy='429' r='1.4'/>
                                <circle cx='625.1' cy='514.6' r='1.4'/>
                                <circle cx='587.6' cy='445.8' r='1.4'/>
                                <circle cx='561.1' cy='384.6' r='1.4'/>
                                <circle cx='694.9' cy='409.1' r='1.4'/>
                                <circle cx='813.5' cy='608.6' r='1.4'/>
                                <circle cx='791.6' cy='683.3' r='1.4'/>
                                <circle cx='625.1' cy='607.2' r='1.4'/>
                                <circle cx='791.3' cy='472.2' r='1.4'/>
                                <circle cx='636.5' cy='407' r='0.7'/>
                                <circle cx='571' cy='577.5' r='0.7'/>
                                <circle cx='862.1' cy='552.1' r='0.7'/>
                                <circle cx='767.9' cy='551.4' r='0.7'/>
                                <circle cx='861.4' cy='706' r='0.7'/>
                                <circle cx='767.9' cy='661.6' r='0.7'/>
                                <circle cx='570.3' cy='660.9' r='0.7'/>
                                <circle cx='817.8' cy='410.5' r='0.7'/>
                                <circle cx='679.6' cy='544.5' r='1.2'/>
                                <circle cx='958.5' cy='523.9' r='1.2'/>
                                <circle cx='699.1' cy='663.8' r='1.2'/>
                            </g>
                            <g>
                                <circle cx='644.3' cy='619.6' r='1.4'/>
                                <circle cx='975' cy='534.1' r='1.4'/>
                                <circle cx='1012.4' cy='602.8' r='1.4'/>
                                <circle cx='1038.2' cy='664' r='1.4'/>
                                <circle cx='905.1' cy='639.5' r='1.4'/>
                                <circle cx='786.5' cy='440.1' r='1.4'/>
                                <circle cx='808.4' cy='365.3' r='1.4'/>
                                <circle cx='1093.8' cy='454' r='1.4'/>
                                <circle cx='808.7' cy='576.4' r='1.4'/>
                                <circle cx='963.5' cy='641.6' r='0.7'/>
                                <circle cx='1029' cy='471.1' r='0.7'/>
                                <circle cx='737.9' cy='496.6' r='0.7'/>
                                <circle cx='832.1' cy='497.3' r='0.7'/>
                                <circle cx='738.6' cy='342.6' r='0.7'/>
                                <circle cx='832.1' cy='387' r='0.7'/>
                                <circle cx='1029.7' cy='387.7' r='0.7'/>
                                <circle cx='782.2' cy='638.1' r='0.7'/>
                                <circle cx='923.2' cy='427.6' r='1.2'/>
                                <circle cx='641.5' cy='524.8' r='1.2'/>
                                <circle cx='1093.8' cy='325.5' r='1.2'/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <circle cx='896.6' cy='350.9' r='1.4'/>
                                <circle cx='1227.2' cy='265.3' r='1.4'/>
                                <circle cx='1264.7' cy='334.1' r='1.4'/>
                                <circle cx='1290.4' cy='395.3' r='1.4'/>
                                <circle cx='1157.4' cy='370.8' r='1.4'/>
                                <circle cx='1038.8' cy='171.3' r='1.4'/>
                                <circle cx='1060.6' cy='96.6' r='1.4'/>
                                <circle cx='1227.2' cy='172.7' r='1.4'/>
                                <circle cx='1061' cy='307.7' r='1.4'/>
                                <circle cx='1215.7' cy='372.9' r='0.7'/>
                                <circle cx='1281.3' cy='202.4' r='0.7'/>
                                <circle cx='990.2' cy='227.8' r='0.7'/>
                                <circle cx='1084.4' cy='228.5' r='0.7'/>
                                <circle cx='990.9' cy='73.9' r='0.7'/>
                                <circle cx='1084.4' cy='118.2' r='0.7'/>
                                <circle cx='1282' cy='118.9' r='0.7'/>
                                <circle cx='1034.5' cy='369.4' r='0.7'/>
                                <circle cx='1172.7' cy='235.4' r='1.2'/>
                                <circle cx='893.8' cy='256' r='1.2'/>
                                <circle cx='1153.2' cy='116.1' r='1.2'/>
                            </g>
                            <g>
                                <circle cx='408.6' cy='301.1' r='1.4'/>
                                <circle cx='739.2' cy='216.2' r='1.4'/>
                                <circle cx='776.7' cy='285' r='1.4'/>
                                <circle cx='802.5' cy='346.2' r='1.4'/>
                                <circle cx='669.4' cy='321.7' r='1.4'/>
                                <circle cx='550.8' cy='122.2' r='1.4'/>
                                <circle cx='572.6' cy='47.5' r='1.4'/>
                                <circle cx='739.2' cy='123.6' r='1.4'/>
                                <circle cx='573' cy='258.6' r='1.4'/>
                                <circle cx='727.7' cy='323.8' r='0.7'/>
                                <circle cx='793.3' cy='153.3' r='0.7'/>
                                <circle cx='502.2' cy='178.8' r='0.7'/>
                                <circle cx='596.4' cy='179.5' r='0.7'/>
                                <circle cx='502.9' cy='24.8' r='0.7'/>
                                <circle cx='596.4' cy='69.2' r='0.7'/>
                                <circle cx='794' cy='69.9' r='0.7'/>
                                <circle cx='546.5' cy='320.3' r='0.7'/>
                                <circle cx='684.7' cy='186.3' r='1.2'/>
                                <circle cx='405.8' cy='206.9' r='1.2'/>
                                <circle cx='665.2' cy='67' r='1.2'/>
                            </g>
                            <g>
                                <circle cx='719.9' cy='111.2' r='1.4'/>
                                <circle cx='389.3' cy='196.8' r='1.4'/>
                                <circle cx='351.9' cy='128' r='1.4'/>
                                <circle cx='326.1' cy='66.8' r='1.4'/>
                                <circle cx='459.1' cy='91.3' r='1.4'/>
                                <circle cx='577.7' cy='290.7' r='1.4'/>
                                <circle cx='555.9' cy='365.5' r='1.4'/>
                                <circle cx='270.5' cy='276.8' r='1.4'/>
                                <circle cx='555.5' cy='154.4' r='1.4'/>
                                <circle cx='400.8' cy='89.2' r='0.7'/>
                                <circle cx='335.3' cy='259.7' r='0.7'/>
                                <circle cx='626.3' cy='234.2' r='0.7'/>
                                <circle cx='532.1' cy='233.5' r='0.7'/>
                                <circle cx='625.6' cy='388.2' r='0.7'/>
                                <circle cx='532.1' cy='343.8' r='0.7'/>
                                <circle cx='334.6' cy='343.1' r='0.7'/>
                                <circle cx='582' cy='92.7' r='0.7'/>
                                <circle cx='441' cy='303.2' r='1.2'/>
                                <circle cx='722.8' cy='206.1' r='1.2'/>
                                <circle cx='270.5' cy='405.3' r='1.2'/>
                            </g>
                        </g>
                    </g>
                    <g className='st162'>
                        <g>
                            <g>
                                <circle cx='849.4' cy='647.6' r='1.2'/>
                                <circle cx='1289.7' cy='533.6' r='1.2'/>
                                <circle cx='1339.6' cy='625.2' r='1.2'/>
                                <circle cx='1344.6' cy='687.9' r='1.2'/>
                                <circle cx='1382.2' cy='662.2' r='1.2'/>
                                <circle cx='1196.8' cy='674.1' r='1.2'/>
                                <circle cx='1038.8' cy='408.5' r='1.2'/>
                                <circle cx='1067.9' cy='308.9' r='1.2'/>
                                <circle cx='1289.7' cy='410.4' r='1.2'/>
                                <circle cx='1068.4' cy='590.1' r='1.2'/>
                                <circle cx='1274.4' cy='676.9' r='0.9'/>
                                <circle cx='1361.7' cy='449.8' r='0.9'/>
                                <circle cx='974.1' cy='483.7' r='0.9'/>
                                <circle cx='1099.5' cy='484.7' r='0.9'/>
                                <circle cx='975' cy='278.7' r='0.9'/>
                                <circle cx='1099.5' cy='337.8' r='0.9'/>
                                <circle cx='1362.6' cy='338.7' r='0.9'/>
                                <circle cx='1033.1' cy='672.2' r='0.9'/>
                                <circle cx='1217.1' cy='493.8' r='1.8'/>
                                <circle cx='845.7' cy='521.3' r='1.8'/>
                                <circle cx='1191.1' cy='335' r='1.8'/>
                            </g>
                            <g>
                                <circle cx='199.6' cy='582.2' r='1.2'/>
                                <circle cx='639.9' cy='468.3' r='1.2'/>
                                <circle cx='689.7' cy='559.9' r='1.2'/>
                                <circle cx='724.1' cy='641.3' r='1.2'/>
                                <circle cx='546.9' cy='608.7' r='1.2'/>
                                <circle cx='389' cy='343.1' r='1.2'/>
                                <circle cx='418' cy='243.6' r='1.2'/>
                                <circle cx='639.9' cy='345' r='1.2'/>
                                <circle cx='418.5' cy='524.7' r='1.2'/>
                                <circle cx='624.6' cy='611.5' r='0.9'/>
                                <circle cx='711.9' cy='384.5' r='0.9'/>
                                <circle cx='324.3' cy='418.4' r='0.9'/>
                                <circle cx='449.7' cy='419.3' r='0.9'/>
                                <circle cx='325.2' cy='213.3' r='0.9'/>
                                <circle cx='449.7' cy='272.4' r='0.9'/>
                                <circle cx='712.8' cy='273.4' r='0.9'/>
                                <circle cx='383.3' cy='606.9' r='0.9'/>
                                <circle cx='567.2' cy='428.4' r='1.8'/>
                                <circle cx='195.8' cy='455.9' r='1.8'/>
                                <circle cx='541.3' cy='269.6' r='1.8'/>
                            </g>
                            <g>
                                <circle cx='614.2' cy='328.4' r='1.2'/>
                                <circle cx='173.9' cy='442.3' r='1.2'/>
                                <circle cx='124.1' cy='350.8' r='1.2'/>
                                <circle cx='89.7' cy='269.3' r='1.2'/>
                                <circle cx='266.9' cy='301.9' r='1.2'/>
                                <circle cx='424.8' cy='567.5' r='1.2'/>
                                <circle cx='395.8' cy='667' r='1.2'/>
                                <circle cx='15.6' cy='548.9' r='1.2'/>
                                <circle cx='395.3' cy='385.9' r='1.2'/>
                                <circle cx='189.2' cy='299.1' r='0.9'/>
                                <circle cx='101.9' cy='526.1' r='0.9'/>
                                <circle cx='489.5' cy='492.2' r='0.9'/>
                                <circle cx='364.1' cy='491.3' r='0.9'/>
                                <circle cx='488.6' cy='697.3' r='0.9'/>
                                <circle cx='364.1' cy='638.2' r='0.9'/>
                                <circle cx='101' cy='637.2' r='0.9'/>
                                <circle cx='430.5' cy='303.8' r='0.9'/>
                                <circle cx='242.8' cy='584.1' r='1.8'/>
                                <circle cx='618' cy='454.7' r='1.8'/>
                                <circle cx='63.8' cy='671.2' r='1.8'/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <circle cx='817.5' cy='578.6' r='1.4'/>
                                <circle cx='1156.3' cy='490.9' r='1.4'/>
                                <circle cx='1194.7' cy='561.4' r='1.4'/>
                                <circle cx='1221.1' cy='624.1' r='1.4'/>
                                <circle cx='1084.8' cy='599' r='1.4'/>
                                <circle cx='963.2' cy='394.6' r='1.4'/>
                                <circle cx='985.6' cy='318' r='1.4'/>
                                <circle cx='1156.3' cy='396' r='1.4'/>
                                <circle cx='986' cy='534.3' r='1.4'/>
                                <circle cx='1144.5' cy='601.1' r='0.7'/>
                                <circle cx='1211.7' cy='426.4' r='0.7'/>
                                <circle cx='913.4' cy='452.5' r='0.7'/>
                                <circle cx='1010' cy='453.2' r='0.7'/>
                                <circle cx='914.1' cy='294.7' r='0.7'/>
                                <circle cx='1010' cy='340.2' r='0.7'/>
                                <circle cx='1212.4' cy='340.9' r='0.7'/>
                                <circle cx='958.8' cy='597.5' r='0.7'/>
                                <circle cx='1100.4' cy='460.3' r='1.5'/>
                                <circle cx='814.6' cy='481.4' r='1.5'/>
                                <circle cx='1080.4' cy='338' r='1.5'/>
                            </g>
                            <g>
                                <circle cx='317.5' cy='528.3' r='1.4'/>
                                <circle cx='656.2' cy='440.6' r='1.4'/>
                                <circle cx='694.6' cy='511.1' r='1.4'/>
                                <circle cx='721' cy='573.8' r='1.4'/>
                                <circle cx='584.7' cy='548.7' r='1.4'/>
                                <circle cx='463.2' cy='344.3' r='1.4'/>
                                <circle cx='485.5' cy='267.7' r='1.4'/>
                                <circle cx='656.2' cy='345.7' r='1.4'/>
                                <circle cx='485.9' cy='484' r='1.4'/>
                                <circle cx='644.5' cy='550.8' r='0.7'/>
                                <circle cx='711.6' cy='376.1' r='0.7'/>
                                <circle cx='413.4' cy='402.2' r='0.7'/>
                                <circle cx='509.9' cy='402.9' r='0.7'/>
                                <circle cx='414.1' cy='244.4' r='0.7'/>
                                <circle cx='509.9' cy='289.9' r='0.7'/>
                                <circle cx='712.4' cy='290.6' r='0.7'/>
                                <circle cx='458.8' cy='547.2' r='0.7'/>
                                <circle cx='600.3' cy='410' r='1.5'/>
                                <circle cx='314.5' cy='431.1' r='1.5'/>
                                <circle cx='580.4' cy='287.7' r='1.5'/>
                            </g>
                            <g>
                                <circle cx='636.5' cy='332.9' r='1.4'/>
                                <circle cx='297.7' cy='420.6' r='1.4'/>
                                <circle cx='259.3' cy='350.2' r='1.4'/>
                                <circle cx='232.9' cy='287.5' r='1.4'/>
                                <circle cx='369.2' cy='312.6' r='1.4'/>
                                <circle cx='490.8' cy='517' r='1.4'/>
                                <circle cx='468.4' cy='593.6' r='1.4'/>
                                <circle cx='175.9' cy='502.6' r='1.4'/>
                                <circle cx='468' cy='377.2' r='1.4'/>
                                <circle cx='309.5' cy='310.4' r='0.7'/>
                                <circle cx='242.3' cy='485.1' r='0.7'/>
                                <circle cx='540.6' cy='459' r='0.7'/>
                                <circle cx='444' cy='458.3' r='0.7'/>
                                <circle cx='539.8' cy='616.8' r='0.7'/>
                                <circle cx='444' cy='571.3' r='0.7'/>
                                <circle cx='241.6' cy='570.6' r='0.7'/>
                                <circle cx='495.2' cy='314' r='0.7'/>
                                <circle cx='350.7' cy='529.7' r='1.5'/>
                                <circle cx='639.4' cy='430.2' r='1.5'/>
                                <circle cx='175.9' cy='634.3' r='1.5'/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <circle cx='935.8' cy='350.5' r='1.4'/>
                                <circle cx='1274.6' cy='262.8' r='1.4'/>
                                <circle cx='1313' cy='333.3' r='1.4'/>
                                <circle cx='1339.4' cy='396' r='1.4'/>
                                <circle cx='1203.1' cy='370.9' r='1.4'/>
                                <circle cx='1081.5' cy='166.5' r='1.4'/>
                                <circle cx='1103.9' cy='89.9' r='1.4'/>
                                <circle cx='1274.6' cy='168' r='1.4'/>
                                <circle cx='1104.3' cy='306.3' r='1.4'/>
                                <circle cx='1262.8' cy='373.1' r='0.7'/>
                                <circle cx='1330' cy='198.3' r='0.7'/>
                                <circle cx='1031.7' cy='224.4' r='0.7'/>
                                <circle cx='1128.3' cy='225.2' r='0.7'/>
                                <circle cx='1032.5' cy='66.7' r='0.7'/>
                                <circle cx='1128.3' cy='112.1' r='0.7'/>
                                <circle cx='1330.7' cy='112.9' r='0.7'/>
                                <circle cx='1077.1' cy='369.5' r='0.7'/>
                                <circle cx='1218.7' cy='232.2' r='1.5'/>
                                <circle cx='932.9' cy='253.3' r='1.5'/>
                                <circle cx='1198.7' cy='109.9' r='1.5'/>
                            </g>
                            <g>
                                <circle cx='435.8' cy='300.2' r='1.4'/>
                                <circle cx='774.6' cy='212.5' r='1.4'/>
                                <circle cx='812.9' cy='283' r='1.4'/>
                                <circle cx='839.4' cy='345.7' r='1.4'/>
                                <circle cx='703' cy='320.6' r='1.4'/>
                                <circle cx='581.5' cy='116.2' r='1.4'/>
                                <circle cx='603.8' cy='39.6' r='1.4'/>
                                <circle cx='774.6' cy='117.7' r='1.4'/>
                                <circle cx='604.2' cy='256' r='1.4'/>
                                <circle cx='762.8' cy='322.8' r='0.7'/>
                                <circle cx='829.9' cy='148' r='0.7'/>
                                <circle cx='531.7' cy='174.1' r='0.7'/>
                                <circle cx='628.2' cy='174.9' r='0.7'/>
                                <circle cx='532.4' cy='16.4' r='0.7'/>
                                <circle cx='628.2' cy='61.1' r='0.7'/>
                                <circle cx='830.7' cy='62.6' r='0.7'/>
                                <circle cx='577.1' cy='319.2' r='0.7'/>
                                <circle cx='718.7' cy='181.9' r='1.5'/>
                                <circle cx='432.9' cy='203' r='1.5'/>
                                <circle cx='698.7' cy='59.6' r='1.5'/>
                            </g>
                            <g>
                                <circle cx='754.8' cy='104.9' r='1.4'/>
                                <circle cx='416' cy='192.6' r='1.4'/>
                                <circle cx='377.6' cy='122.1' r='1.4'/>
                                <circle cx='351.2' cy='59.4' r='1.4'/>
                                <circle cx='487.5' cy='84.5' r='1.4'/>
                                <circle cx='609.1' cy='288.9' r='1.4'/>
                                <circle cx='586.7' cy='365.5' r='1.4'/>
                                <circle cx='294.2' cy='274.6' r='1.4'/>
                                <circle cx='586.3' cy='149.2' r='1.4'/>
                                <circle cx='427.8' cy='82.3' r='0.7'/>
                                <circle cx='360.6' cy='257.1' r='0.7'/>
                                <circle cx='658.9' cy='231' r='0.7'/>
                                <circle cx='562.3' cy='230.3' r='0.7'/>
                                <circle cx='658.2' cy='388.7' r='0.7'/>
                                <circle cx='562.3' cy='343.3' r='0.7'/>
                                <circle cx='359.9' cy='342.6' r='0.7'/>
                                <circle cx='613.5' cy='85.9' r='0.7'/>
                                <circle cx='469' cy='301.7' r='1.5'/>
                                <circle cx='757.7' cy='202.1' r='1.5'/>
                                <circle cx='294.2' cy='406.3' r='1.5'/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <circle cx='496.3' cy='380.3' r='1.4'/>
                                <circle cx='157.5' cy='468' r='1.4'/>
                                <circle cx='119.1' cy='397.5' r='1.4'/>
                                <circle cx='92.7' cy='334.8' r='1.4'/>
                                <circle cx='229' cy='359.9' r='1.4'/>
                                <circle cx='350.6' cy='564.3' r='1.4'/>
                                <circle cx='328.2' cy='640.9' r='1.4'/>
                                <circle cx='157.5' cy='562.8' r='1.4'/>
                                <circle cx='327.8' cy='424.6' r='1.4'/>
                                <circle cx='169.3' cy='357.7' r='0.7'/>
                                <circle cx='102.1' cy='532.5' r='0.7'/>
                                <circle cx='400.4' cy='506.4' r='0.7'/>
                                <circle cx='303.9' cy='505.6' r='0.7'/>
                                <circle cx='399.7' cy='664.1' r='0.7'/>
                                <circle cx='303.9' cy='618.7' r='0.7'/>
                                <circle cx='101.4' cy='617.9' r='0.7'/>
                                <circle cx='355' cy='361.3' r='0.7'/>
                                <circle cx='213.4' cy='498.6' r='1.5'/>
                                <circle cx='499.2' cy='477.5' r='1.5'/>
                                <circle cx='233.4' cy='620.9' r='1.5'/>
                            </g>
                            <g>
                                <circle cx='996.4' cy='430.6' r='1.4'/>
                                <circle cx='657.6' cy='518.3' r='1.4'/>
                                <circle cx='619.2' cy='447.8' r='1.4'/>
                                <circle cx='592.8' cy='385.1' r='1.4'/>
                                <circle cx='729.1' cy='410.2' r='1.4'/>
                                <circle cx='850.6' cy='614.6' r='1.4'/>
                                <circle cx='828.3' cy='691.2' r='1.4'/>
                                <circle cx='657.6' cy='613.1' r='1.4'/>
                                <circle cx='827.9' cy='474.9' r='1.4'/>
                                <circle cx='669.3' cy='408' r='0.7'/>
                                <circle cx='602.2' cy='582.8' r='0.7'/>
                                <circle cx='900.4' cy='556.7' r='0.7'/>
                                <circle cx='803.9' cy='555.9' r='0.7'/>
                                <circle cx='899.7' cy='714.4' r='0.7'/>
                                <circle cx='803.9' cy='669' r='0.7'/>
                                <circle cx='601.5' cy='668.2' r='0.7'/>
                                <circle cx='855' cy='411.6' r='0.7'/>
                                <circle cx='713.5' cy='548.9' r='1.5'/>
                                <circle cx='999.3' cy='527.8' r='1.5'/>
                                <circle cx='733.4' cy='671.2' r='1.5'/>
                            </g>
                            <g>
                                <circle cx='677.3' cy='625.9' r='1.4'/>
                                <circle cx='1016.1' cy='538.2' r='1.4'/>
                                <circle cx='1054.5' cy='608.7' r='1.4'/>
                                <circle cx='1080.9' cy='671.4' r='1.4'/>
                                <circle cx='944.6' cy='646.3' r='1.4'/>
                                <circle cx='823' cy='441.9' r='1.4'/>
                                <circle cx='845.4' cy='365.3' r='1.4'/>
                                <circle cx='1137.9' cy='456.2' r='1.4'/>
                                <circle cx='845.8' cy='581.6' r='1.4'/>
                                <circle cx='1004.3' cy='648.5' r='0.7'/>
                                <circle cx='1071.5' cy='473.7' r='0.7'/>
                                <circle cx='773.2' cy='499.8' r='0.7'/>
                                <circle cx='869.8' cy='500.5' r='0.7'/>
                                <circle cx='774' cy='342.1' r='0.7'/>
                                <circle cx='869.8' cy='387.5' r='0.7'/>
                                <circle cx='1072.2' cy='388.2' r='0.7'/>
                                <circle cx='818.7' cy='644.9' r='0.7'/>
                                <circle cx='963.1' cy='429.1' r='1.5'/>
                                <circle cx='674.4' cy='528.7' r='1.5'/>
                                <circle cx='1137.9' cy='324.5' r='1.5'/>
                            </g>

                            <g>
                                <circle cx='435.8' cy='300.2' r='1.4'/>
                                <circle cx='774.6' cy='212.5' r='1.4'/>
                                <circle cx='812.9' cy='283' r='1.4'/>
                                <circle cx='839.4' cy='345.7' r='1.4'/>
                                <circle cx='703' cy='320.6' r='1.4'/>
                                <circle cx='581.5' cy='116.2' r='1.4'/>
                                <circle cx='603.8' cy='39.6' r='1.4'/>
                                <circle cx='774.6' cy='117.7' r='1.4'/>
                                <circle cx='604.2' cy='256' r='1.4'/>
                                <circle cx='762.8' cy='322.8' r='0.7'/>
                                <circle cx='829.9' cy='148' r='0.7'/>
                                <circle cx='531.7' cy='174.1' r='0.7'/>
                                <circle cx='628.2' cy='174.9' r='0.7'/>
                                <circle cx='532.4' cy='16.4' r='0.7'/>
                                <circle cx='628.2' cy='61.1' r='0.7'/>
                                <circle cx='830.7' cy='62.6' r='0.7'/>
                                <circle cx='577.1' cy='319.2' r='0.7'/>
                                <circle cx='718.7' cy='181.9' r='1.5'/>
                                <circle cx='432.9' cy='203' r='1.5'/>
                                <circle cx='698.7' cy='59.6' r='1.5'/>
                            </g>
                            <g>
                                <circle cx='754.8' cy='104.9' r='1.4'/>
                                <circle cx='416' cy='192.6' r='1.4'/>
                                <circle cx='377.6' cy='122.1' r='1.4'/>
                                <circle cx='351.2' cy='59.4' r='1.4'/>
                                <circle cx='487.5' cy='84.5' r='1.4'/>
                                <circle cx='609.1' cy='288.9' r='1.4'/>
                                <circle cx='586.7' cy='365.5' r='1.4'/>
                                <circle cx='294.2' cy='274.6' r='1.4'/>
                                <circle cx='586.3' cy='149.2' r='1.4'/>
                                <circle cx='427.8' cy='82.3' r='0.7'/>
                                <circle cx='360.6' cy='257.1' r='0.7'/>
                                <circle cx='658.9' cy='231' r='0.7'/>
                                <circle cx='562.3' cy='230.3' r='0.7'/>
                                <circle cx='658.2' cy='388.7' r='0.7'/>
                                <circle cx='562.3' cy='343.3' r='0.7'/>
                                <circle cx='359.9' cy='342.6' r='0.7'/>
                                <circle cx='613.5' cy='85.9' r='0.7'/>
                                <circle cx='469' cy='301.7' r='1.5'/>
                                <circle cx='757.7' cy='202.1' r='1.5'/>
                                <circle cx='294.2' cy='406.3' r='1.5'/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <circle cx='496.3' cy='380.3' r='1.4'/>
                                <circle cx='157.5' cy='468' r='1.4'/>
                                <circle cx='119.1' cy='397.5' r='1.4'/>
                                <circle cx='92.7' cy='334.8' r='1.4'/>
                                <circle cx='229' cy='359.9' r='1.4'/>
                                <circle cx='350.6' cy='564.3' r='1.4'/>
                                <circle cx='328.2' cy='640.9' r='1.4'/>
                                <circle cx='157.5' cy='562.8' r='1.4'/>
                                <circle cx='327.8' cy='424.6' r='1.4'/>
                                <circle cx='169.3' cy='357.7' r='0.7'/>
                                <circle cx='102.1' cy='532.5' r='0.7'/>
                                <circle cx='400.4' cy='506.4' r='0.7'/>
                                <circle cx='303.9' cy='505.6' r='0.7'/>
                                <circle cx='399.7' cy='664.1' r='0.7'/>
                                <circle cx='303.9' cy='618.7' r='0.7'/>
                                <circle cx='101.4' cy='617.9' r='0.7'/>
                                <circle cx='355' cy='361.3' r='0.7'/>
                                <circle cx='213.4' cy='498.6' r='1.5'/>
                                <circle cx='499.2' cy='477.5' r='1.5'/>
                                <circle cx='233.4' cy='620.9' r='1.5'/>
                            </g>
                            <g>
                                <circle cx='996.4' cy='430.6' r='1.4'/>
                                <circle cx='657.6' cy='518.3' r='1.4'/>
                                <circle cx='619.2' cy='447.8' r='1.4'/>
                                <circle cx='592.8' cy='385.1' r='1.4'/>
                                <circle cx='729.1' cy='410.2' r='1.4'/>
                                <circle cx='850.6' cy='614.6' r='1.4'/>
                                <circle cx='828.3' cy='691.2' r='1.4'/>
                                <circle cx='657.6' cy='613.1' r='1.4'/>
                                <circle cx='827.9' cy='474.9' r='1.4'/>
                                <circle cx='669.3' cy='408' r='0.7'/>
                                <circle cx='602.2' cy='582.8' r='0.7'/>
                                <circle cx='900.4' cy='556.7' r='0.7'/>
                                <circle cx='803.9' cy='555.9' r='0.7'/>
                                <circle cx='899.7' cy='714.4' r='0.7'/>
                                <circle cx='803.9' cy='669' r='0.7'/>
                                <circle cx='601.5' cy='668.2' r='0.7'/>
                                <circle cx='855' cy='411.6' r='0.7'/>
                                <circle cx='713.5' cy='548.9' r='1.5'/>
                                <circle cx='999.3' cy='527.8' r='1.5'/>
                                <circle cx='733.4' cy='671.2' r='1.5'/>
                            </g>
                            <g>
                                <circle cx='677.3' cy='625.9' r='1.4'/>
                                <circle cx='1016.1' cy='538.2' r='1.4'/>
                                <circle cx='1054.5' cy='608.7' r='1.4'/>
                                <circle cx='1080.9' cy='671.4' r='1.4'/>
                                <circle cx='944.6' cy='646.3' r='1.4'/>
                                <circle cx='823' cy='441.9' r='1.4'/>
                                <circle cx='845.4' cy='365.3' r='1.4'/>
                                <circle cx='1137.9' cy='456.2' r='1.4'/>
                                <circle cx='845.8' cy='581.6' r='1.4'/>
                                <circle cx='1004.3' cy='648.5' r='0.7'/>
                                <circle cx='1071.5' cy='473.7' r='0.7'/>
                                <circle cx='773.2' cy='499.8' r='0.7'/>
                                <circle cx='869.8' cy='500.5' r='0.7'/>
                                <circle cx='774' cy='342.1' r='0.7'/>
                                <circle cx='869.8' cy='387.5' r='0.7'/>
                                <circle cx='1072.2' cy='388.2' r='0.7'/>
                                <circle cx='818.7' cy='644.9' r='0.7'/>
                                <circle cx='963.1' cy='429.1' r='1.5'/>
                                <circle cx='674.4' cy='528.7' r='1.5'/>
                                <circle cx='1137.9' cy='324.5' r='1.5'/>
                            </g>
                        </g>
                    </g>
                </g>
            </switch>
        </motion.svg>

    )
}

export default Stars

