import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import 'styles/modal.css'
import * as Background from 'components/Animations';

const Home = () => {

        const wagmiStatus = window.localStorage.getItem("wagmi.connected");
        const wagmiWallet = window.localStorage.getItem("wagmi.wallet");
        const wagmiData = window.localStorage.getItem("wagmi.store");
        // console.log("Wagmi connected: " + wagmiStatus)
        // console.log("Wagmi wallet: " + wagmiWallet)
        // console.log("Wagmi data: " + wagmiData)
        // console.log("Wagmi data: " + wagmiData.state)
        const [ initialBackgroundTop, setInitialBackgroundTop ] = useAtom(Background.initialBackgroundTopAtom);
        const [ animateBackgroundTop, setAnimateBackgroundTop ] = useAtom(Background.animateBackgroundTopAtom);
        const [ initialBackgroundLeft, setInitialBackgroundLeft ] = useAtom(Background.initialBackgroundLeftAtom);
        const [ animateBackgroundLeft, setAnimateBackgroundLeft ] = useAtom(Background.animateBackgroundLeftAtom);
        const [ initialBackgroundOpacity, setInitialBackgroundOpacity ] = useAtom(Background.initialBackgroundOpacityAtom);
        const [ animateBackgroundOpacity, setAnimateBackgroundOpacity ] = useAtom(Background.animateBackgroundOpacityAtom);

    useEffect(() => {
            setAnimateBackgroundOpacity(1);
    }, [setAnimateBackgroundOpacity]);

    const exitTrigger = () => {
        setAnimateBackgroundOpacity(0);
    }

    useEffect(() => {
        setInitialBackgroundTop(animateBackgroundTop);
        setInitialBackgroundLeft(animateBackgroundLeft);
        setInitialBackgroundOpacity(animateBackgroundOpacity);
        setAnimateBackgroundTop('0px');
        setAnimateBackgroundLeft('0px');
        setAnimateBackgroundOpacity(0);
    })

    return (
        <div className='RouteWrapper HomeWrapper'>

            <div  className='bg' style={{ zIndex: '-1' }}></div>
            <div className='home-content'>

                <div className='main-content'>

                    <div className="col-12 relative">

                        <div className="content__image">
                            <div className="logo">
                                <img src="/slayworld.gif" alt="Home" className="col-12" priority="true" />
                                {/*<div className='NameLogo'>
                                    <span className='icon-Group-1'></span>
                                    <span className='icon-Group-2'></span>
                                    <span className='icon-Group-3'></span>
                                </div>*/}
                            </div>
                        </div>

                        <div className="content__text welcome row m-w-full mx-auto">
                            <div className="col-10">
                                <h1 className="common-title mb-3">WELCOME TO<br />THE SLAYWORLD<br /> NFT CLUB</h1>
                                <Link className="col-12 m-w-full block" to="/mint" onClick={() => exitTrigger()}><button className="btn--primary col-12 m-w-full block">ENTER</button></Link>
                                <span className="block mt10 center" style={{ opacity: '0.4' }}><i>coming soon</i></span>
                            </div>
                            <div className="col-2">
                                <div className="rotate my-auto">← ABOUT SLAYWORLD</div>
                            </div>
                        </div>
                    </div>

                    <div className="ml20 mr20 row">
                        <div className="d-col-12 col-8">
                            <p className="common-p">Slay World - a tribute NFT collection. All holders will be able to access the discord 5 days after mint. Perks such as leaks, events, and networking will be available to holders in the discord.</p>
                        </div>
                    </div>

                </div>

                <div className="copyright center">
					<small class="copyright__content">© 2023, <a href="/" title="Slay World Gang">SLAYWORD</a> | <a href="https://www.jonahlmadeya.com" target="_blank">web development</a></small>
                </div>

            </div>

        </div>
    );
}

export default Home