import MediaQuery from 'react-responsive'
import { ConnectButton } from '@rainbow-me/rainbowkit';

const ConnectInline = () => {
    
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        mounted,
      }) => {
        return (
          <div className='connect'
            {...(!mounted && {
              'aria-hidden': true,
              'style': {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!mounted || !account || !chain) {
                return (
                    <>
                    <MediaQuery maxWidth={749}>
                      <button onClick={openConnectModal}>
                      Connect
                      </button>
                    </MediaQuery>
                    <MediaQuery minWidth={749}>
                      <button onClick={openConnectModal} type='button'>
                            Connect Wallet
                      </button>
                      </MediaQuery>
                    </>

                );
              }
              if (chain.unsupported) {
                return (
                  <button onClick={openChainModal} type='button'>
                    Wrong network
                  </button>
                );
              }
              return (
                <div style={{ display: 'flex', gap: 12 }}>
                  <div onClick={openAccountModal}>
                    <MediaQuery maxWidth={749}>
                    <button>
                        Connect
                      </button>
                    </MediaQuery>
                    <MediaQuery minWidth={749}>
                    <button>
                        {account.displayName}
                        {account.displayBalance
                          ? ` (${account.displayBalance})`
                          : ''}
                      </button>
                      </MediaQuery>
                  </div>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default ConnectInline