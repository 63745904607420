import react, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { atom } from 'jotai';
import Stars from 'components/vectors/Stars';

export const classMintingAtom = atom('idle');
export const initialBackgroundTopAtom = atom('0px')
export const animateBackgroundTopAtom = atom('0px')
export const initialBackgroundLeftAtom = atom('0px')
export const animateBackgroundLeftAtom = atom('0px')
export const initialBackgroundOpacityAtom = atom(0)
export const animateBackgroundOpacityAtom = atom(0)

export const Background = ({ 
    classMintingAtom,
    initialBackgroundTopAtom,
    animateBackgroundTopAtom,
    initialBackgroundLeftAtom,
    animateBackgroundLeftAtom,
    initialBackgroundOpacityAtom,
    animateBackgroundOpacityAtom,

}) => {

    return (

        <motion.div
            className={`background-container ${classMintingAtom}`}
        >

            <motion.div
                className={`animated-background ${classMintingAtom}`}
                initial={{ top: initialBackgroundTopAtom, left: initialBackgroundLeftAtom, opacity: 0 }}
                animate={{ top: animateBackgroundTopAtom, left: animateBackgroundLeftAtom, opacity: animateBackgroundOpacityAtom }}
                transition={{ type: 'tween', duration: 1 }}
            >
                <video
                    muted
                    loop
                    autoPlay
                    playsInline
                    controls={false}
                    className='background-mint absolute z-0'
                >
                    <source src='/falling-stacks.mp4' type='video/mp4' />
                </video>

            </motion.div>

        </motion.div>
    );
};
