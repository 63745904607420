import React from 'react';
import ReactDOM from 'react-dom/client';
import { 
    BrowserRouter
} from 'react-router-dom';
import 'styles/index.css';

import '@rainbow-me/rainbowkit/styles.css';
import { 
    RainbowKitProvider,
    connectorsForWallets,
    wallet
} from '@rainbow-me/rainbowkit';
import { 
    chain,
    configureChains,
    createClient,
    WagmiConfig
} from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public'
import App from 'App';

const { chains, provider, webSocketProvider } = configureChains(
    [
        chain.mainnet,
        ...(process.env.REACT_APP_ENABLE_TESTNETS === 'false'
            ? []
            : []),
    ],
    [
        infuraProvider({ infuraId: process.env.REACT_APP_INFURA_ID }),
        alchemyProvider({ alchemyId: process.env.REACT_APP_ALCHEMY_ID }),
        publicProvider(),
    ]
);

const connectors = connectorsForWallets([{
    appName: 'Slayworld',
    groupName: 'Recommended',
    wallets: [
        wallet.metaMask({ chains }),
        wallet.coinbase({ appName: 'Slayworld', chains }),
        wallet.walletConnect({ appName: 'Slayworld', chains })
    ],
  },
]);

const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
    webSocketProvider
});

const root = ReactDOM.createRoot(
    document.getElementById('root')
);

const TCATheme = {
    colors: {
        accentColor: 'none',
        accentColorForeground: 'none',
        actionButtonBorder: 'none',
        actionButtonBorderMobile: 'none',
        actionButtonSecondaryBackground: 'none',
        closeButton: 'none',
        closeButtonBackground: 'none',
        connectButtonBackground: 'none',
        connectButtonBackgroundError: 'none',
        connectButtonInnerBackground: 'none',
        connectButtonText: 'white',
        connectButtonTextError: 'none',
        connectionIndicator: 'none',
        error: 'none',
        generalBorder: 'none',
        generalBorderDim: 'none',
        menuItemBackground: 'none',
        modalBackdrop: 'none',
        modalBackground: '#000000',
        modalBorder: '#222222',
        modalText: 'none',
        modalTextDim: 'none',
        modalTextSecondary: 'none',
        profileAction: 'none',
        profileActionHover: 'none',
        profileForeground: 'none',
        selectedOptionBorder: 'none',
        standby: 'none'
    },
    fonts: {
        body: 'Helvetica'
    },
    radii: {
        actionButton: 'none',
        connectButton: 'none',
        menuButton: 'none',
        modal: 'none',
        modalMobile: 'none'
    },
    shadows: {
        connectButton: 'none',
        dialog: 'none',
        profileDetailsAction: 'none',
        selectedOption: 'none',
        selectedWallet: 'none',
        walletLogo: 'none'
    },
    blurs: {
        modalOverlay: ''
    }
};

root.render(
    <React.StrictMode>
        <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider chains={chains} theme={TCATheme}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
            </RainbowKitProvider>
        </WagmiConfig>
    </React.StrictMode>
);