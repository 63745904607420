import React from 'react';
import { useLocation } from 'react-router-dom';
import Connect from 'components/Connect';
import AnimatedRoutes from 'components/AnimatedRoutes';

const App = () => {
        
    const location = useLocation();

    const isCurrentURL = (url) => {
        return location.pathname.toLowerCase() === url.toLowerCase();
    }

    return (
        <div className={`Main wrap ${!isCurrentURL('/') ? 'Page' : 'Home'}`}> 
            <div className='top-connect'>
                <Connect />
            </div>
            <AnimatedRoutes />
        </div>
    );
};

export default App